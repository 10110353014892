import React from "react"
import {
  getCourseNavigation,
  useCourseStore,
} from "../../../../../store/courses"
import LayoutCourse from "../../../../../layouts/course"

import Paragraph from "../../../../../components/paragraph"
import CourseFooterNext from "../../../../../components/course-footer-next"
import HandDrawing from "../../../../../components/hand-drawing"
import Heading from "../../../../../components/heading"
import Seo from "../../../../../components/seo"
import Stack from "../../../../../components/stack"

const Page = () => {
  const { getAnswer } = useCourseStore()
  const navigation = getCourseNavigation({ courseId: "jahreszeiten" })

  const answer = getAnswer({
    courseId: "jahreszeiten",
    chapterId: "01-jahreszeitengedicht",
    taskId: "handschrift",
  })
  return (
    <LayoutCourse
      as="form"
      navigation={navigation}
      footer={
        <CourseFooterNext to="/kurse/jahreszeiten/01-jahreszeitengedicht/manuskript" />
      }
    >
      <Seo title="Handschrift" />
      <Stack>
        <Heading as="h1" level={2}>
          Hölderlins Handschrift nachzeichnen
        </Heading>
        <HandDrawing ratio={0.3} radius={3} saveData={answer} disabled />
        <Paragraph>
          Wow! Ein echtes Meisterwerk. Hölderlins Handschrift zu entziffern ist
          gar nicht so leicht. Man muss ganz genau hinsehen – und manchmal auch
          ein wenig raten.
        </Paragraph>
      </Stack>
    </LayoutCourse>
  )
}

export default Page
